
import { defineComponent } from "vue";

export default defineComponent({
  props: ['contatos'],
  name: "modal-contato",
  components: {},
  methods:{

    alterarContato(index){

      
      this.$emit('alterarContato', index)

    },

  },
  setup() {
    

    return {
     
    };
  },
});
