
import { defineComponent } from "vue";

export default defineComponent({
  props: ['enderecos'],
  name: "view-users-modal",
  components: {},
  methods:{

    alterarEndereco(index){

      
      this.$emit('alterarEndereco', index)

    },

  },
  setup() {
    

    return {
     
    };
  },
});
