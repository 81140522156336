

import KTToolbar from "@/layout/toolbar/Toolbar.vue";

import { defineComponent, onMounted } from "vue";
import TableWidget13 from "@/components/widgets/tables/Widget13.vue";
import InviteFriends from "@/components/modals/general/InviteFriendsModal.vue";
import ViewUsersModal from "@/components/modals/general/ViewUsersModal.vue";
import ModalContato from "@/components/modals/general/ModalContato.vue";
import axios from "axios";
import Swal from "sweetalert2";
import moment from "moment";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";




export default defineComponent({
  name: "dashboard",
   data() {
    return {
      spinner: false,		
	  edicao : {
		  cliente:{
			  nome:'',
			  id:''
		  },
		  endereco:{
			  endereco:'',
			  bairro:'',
		  },
		  contato:{
			  contato:''
		  },
		  endereco_id: '',
		  contato_id: '',
		  produto_id: '',
		  entregador_id: '',
		  forma_pagamento_id: '',
		  data: '',
		  qtds: '',
		  observacao:'',
		  hora: '',
		  total: '',
		  preco: 0.00,
		  id: 0
	  },	
	  pedido_id: 0,	
      qtd: 1,
	  preco: 10.00,
	  total: 10.00,
	  whatsapp: '',
	  data: '',
	  hora:'',
	  observacao: '',
	  produtos: [{
		  preco:'',
		  nome:'',
		  id: ''
	  }],
	  produto:1,
	  forma_pagamento: 1,
	  forma_pagamentos: [],
	  entregadores: [],
	  entregador: 1,
	  contatos:[{id:'', contato:''}],
	  enderecos:[{endereco:'', bairro:'', id: ''}],
      selecionado: {
		  id:'',
		  nome:'',
		  endereco: '',
		  bairro: '',
		  contato:'',
		  cpf_cnpj:'',
		  localizacao:'',
		  contato_id:'',
		  endereco_id:'',

	  },
    }
  },
  mounted() {
	   this.$nextTick(function () {
		this.getProdutos();
		this.getFormaPagamentos();
		this.getEntregadores();
		this.data = this.currentDate();
		this.hora = this.currentTime();

		this.getPreco(this.produto);
		

		if(this.$route.query.id){
			this.getPedido(this.$route.query.id)
		}
			
	})

  },
  watch:{
    qtd(value, old) {
		 this.total = this.preco * value
      },

	preco(value, old) {
		 this.total = this.qtd * value
      },
	//   produto(value, old){
	// 	  this.getPreco(value);
	//   }
	  
  },

  computed:{

		

  },
  
  methods:{

	  getPedido(id_pedido){

		  axios.get(process.env.VUE_APP_API_URL + '/getPedido?id='+id_pedido)
			.then(({ data }) => { 
				this.edicao = data
			}).then(()=>{
				this.preparaEdicao()
			})
			.catch(({ response }) => {
				//this.context.commit(Mutations.SET_ERROR, response.data.errors);
				console.log(response)
			});


	  },

	  preparaEdicao(){

		  this.selecionado.id = this.edicao.cliente.id
		  this.selecionado.nome = this.edicao.cliente.nome
		  this.selecionado.endereco_id = this.edicao.endereco_id
		  this.selecionado.endereco = this.edicao.endereco.endereco
		  this.selecionado.bairro = this.edicao.endereco.bairro
		  this.selecionado.contato_id = this.edicao.contato_id
		  this.selecionado.contato = this.edicao.contato.contato
		  this.produto = parseInt(this.edicao.produto_id)
		  this.data = this.edicao.data
		  this.hora = this.edicao.hora
		  this.forma_pagamento = parseInt(this.edicao.forma_pagamento_id)
		  this.entregador = parseInt(this.edicao.entregador_id)
		  this.observacao = this.edicao.observacao
		  this.qtd = parseInt(this.edicao.qtds)
		  this.preco = (parseFloat(this.edicao.total) / parseInt(this.edicao.qtds))
		  this.total = parseFloat(this.edicao.total)
		  this.pedido_id = this.edicao.id

		  this.getContatos(this.selecionado.id)
		  this.getEnderecos(this.selecionado.id)

	  },

	  getPreco(produto){

		  this.produtos.forEach(object =>{
				if(object.id === produto){
					this.preco = parseFloat(object.preco)
				}
			});


	  },


	  currentDate() {
      const current = new Date();
      //const date = current.getFullYear()+'-'+ ("0" + (current.getMonth() + 1)).slice(-2) +'-'+  ("0" + (current.getDate() + 1)).slice(-2);
      var dateTime = new Date().toJSON().slice(0,10).replace(/-/g,'-');
      
      console.log(dateTime);

      return dateTime;
    },

	currentTime() {
      const current = new Date();
      const time =  ("0" + (current.getHours() + 1)).slice(-2)  + ":" + ("0" + (current.getMinutes() + 1)).slice(-2) ;
      const dateTime =  time;
      
      return dateTime;
    },

	salvarPedido(){

		this.spinner = true

		var pedido = {
		 'qtds' : this.qtd,
		 'data' : this.data,
		 'hora' : this.hora, 
		 'total' :this.total,
		 'produto_id': this.produto,
		 'cliente_id': this.selecionado.id,
		 'entregador_id':this.entregador,
		 'endereco_id':this.selecionado.endereco_id,
		 'contato_id':this.selecionado.contato_id,
		 'forma_pagamento_id': this.forma_pagamento,
		 'observacao': this.observacao,
		 'user_id': 1,
		 'nome': this.selecionado.nome,
		 'cpf_cnpj': this.selecionado.cpf_cnpj,
		 'endereco': this.selecionado.endereco,
		 'bairro': this.selecionado.bairro,
		 'contato': this.selecionado.contato,
		 'status'  : 'Aguardando'
		}


		if(this.pedido_id > 0){

		
			
			axios.put(process.env.VUE_APP_API_URL + '/pedidos/'+this.pedido_id, pedido)
			.then(({ data }) => { 
				//this.novoCliente();
				this.pedido_id = data
			}).then(()=>{
				Swal.fire(
					'Cadastro!',
					'Pedido Atualizado.',
					'success'
				)

				this.spinner = false
			})
			.catch(({ response }) => {
				//this.context.commit(Mutations.SET_ERROR, response.data.errors);
				console.log(response)
				this.spinner = false
			});


		}else{



			axios.post(process.env.VUE_APP_API_URL + '/pedidos', pedido)
			.then(({ data }) => { 
				//this.novoCliente();
				this.pedido_id = data
			}).then(()=>{
				Swal.fire(
					'Cadastro!',
					'Pedido Incluído.',
					'success'
				)

				this.spinner = false
			})
			.catch(({ response }) => {
				//this.context.commit(Mutations.SET_ERROR, response.data.errors);
				console.log(response)
				this.spinner = false
			});


		}

		




	},  

    
	getProdutos(){

		axios.get(process.env.VUE_APP_API_URL + '/produtos')
       .then(({ data }) => {   
        this.produtos = data
      }).then(()=>{
		  this.getPreco(1);
	  })
      .catch(({ response }) => {
        //this.context.commit(Mutations.SET_ERROR, response.data.errors);
        console.log(response)
      });


	},

	getFormaPagamentos(){

		axios.get(process.env.VUE_APP_API_URL + '/forma_pagamentos')
       .then(({ data }) => {   
        this.forma_pagamentos = data
      })
      .catch(({ response }) => {
        //this.context.commit(Mutations.SET_ERROR, response.data.errors);
        console.log(response)
      });


	},

	getEntregadores(){

		axios.get(process.env.VUE_APP_API_URL + '/entregadores')
       .then(({ data }) => {   
        this.entregadores = data
      })
      .catch(({ response }) => {
        //this.context.commit(Mutations.SET_ERROR, response.data.errors);
        console.log(response)
      });


	},

	getContatos(cliente_id){

		axios.get(process.env.VUE_APP_API_URL + '/contatosPorCliente?cliente_id='+cliente_id)
       .then(({ data }) => {   
        this.contatos = data
      })
      .catch(({ response }) => {
        //this.context.commit(Mutations.SET_ERROR, response.data.errors);
        console.log(response)
      });


	},

	getEnderecos(cliente_id){

		axios.get(process.env.VUE_APP_API_URL + '/enderecosPorCliente?cliente_id='+cliente_id)
       .then(({ data }) => {   
        this.enderecos = data
      })
      .catch(({ response }) => {
        //this.context.commit(Mutations.SET_ERROR, response.data.errors);
        console.log(response)
      });


	},

	preparaWhats(){

		var link = 'https://api.whatsapp.com/send?phone=5561985881369&text='

		var texto = "*Cliente*: "+this.selecionado.nome+"\n*Endereço*: "+this.selecionado.endereco+"\n*Bairro*: "+this.selecionado.bairro+"\n*Telefone*: "+this.selecionado.contato+"\n*Qtd*: "+this.qtd+"\n*Valor*: "+this.total+"\n*Forma Pagamento*: "+this.forma_pagamento+"\n*Observacao*: "+this.observacao+"\n*link de acesso*: http://backend.rjcompany.com.br/pedido/"+this.pedido_id

		texto = window.encodeURIComponent(texto);

		this.whatsapp = link + texto;

	},

	setSelecionado(value){

		if(value == 0){
			this.novoCliente();
		}else{
			this.pesquisarCliente(value);
		}	

	},

	alteraEndereco(index){

		if(index == 999){

			this.selecionado.endereco = ''
			this.selecionado.bairro = ''
			this.selecionado.endereco_id = '0'

		}else{

			this.selecionado.endereco = this.enderecos[index].endereco
			this.selecionado.bairro = this.enderecos[index].bairro
			this.selecionado.endereco_id = this.enderecos[index].id


		}



		

	},

	alteraContato(index){


		if(index == 999){
			this.selecionado.contato = ''
			this.selecionado.contato_id = '0'

		}else{


			this.selecionado.contato = this.contatos[index].contato
			this.selecionado.contato_id = this.contatos[index].id


		}


	},

	novoCliente(){
		this.selecionado = {	
	      id:'',		
		  nome:'',
		  endereco: '',
		  bairro: '',
		  contato:'',
		  cpf_cnpj:'',
		  localizacao:'',
		  contato_id:'',
		  endereco_id:'',

	  },
	  this.data = this.currentDate();
	  this.hora = this.currentTime();
	  this.qtd = 1
	  this.produto = 1
	  this.forma_pagamento = 1
	  this.entregador = 1
	  this.pedido_id = 0
	  this.observacao = ''

	},

    pesquisarCliente(termo) {

		this.pedido_id = 0

      axios.get(process.env.VUE_APP_API_URL + '/getCliente?endereco_id='+termo)
       .then(({ data }) => {   
        this.selecionado = data
      })
	  .then(() => {   
        this.getContatos(this.selecionado.id)
      })
	   .then(() => {   
         this.getEnderecos(this.selecionado.id)
       })
      .catch(({ response }) => {
        //this.context.commit(Mutations.SET_ERROR, response.data.errors);
        console.log(response)
      });
    },

  },
  components: {
     TableWidget13,
	 InviteFriends,
	 ViewUsersModal,
	 ModalContato,
	 KTToolbar,

	 
    
  },
  setup() {
    onMounted(() => {
      setCurrentPageTitle("Dashboard");
	  
    });
  },
});
